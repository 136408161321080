@charset "utf-8";

.m-header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 9999;
    background-color: #000;
}

.m-header__modal {
    position: fixed;
    width: 100%;
    height: 120%;
    top: 80px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 300;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s,visibility .3s;


    &.pc-modal-is-active {
        opacity: 1;
        visibility: visible;
    }

}


.m-headerPC {
    z-index: 9998;
    display: block;
    @include sp{
        display: none;
    }

    &__inner {
        width: fit-content;
        height: 80px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        
    }

    &__logo1 {
        height: 100%;
        margin-left: auto;
        padding-right: 40px;
        padding-top: 10px;
        img {
            height: 57px;
        }
    }

    &__navwrapper {
        position: relative;
        width: 940px;
        min-width: 750px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
    }

    &__mainnav {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        height: 100%;
        z-index: 9996;

        > li {
            margin-top: 30px;
            color: #FFF;
        }
    }

    &__submenu {
        width: 100%;
        position: absolute;
        top: 80px;
        left: 0;
        padding: 30px;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s,visibility .3s;
        background-color: rgba(255, 255, 255, 0.95);
        color: initial;

        .isActive {
            visibility: visible;
            opacity: 1;
        }
    }

    &__submenu1 {
        width: 100%;
        position: absolute;
        top: 80px;
        left: 0;
        padding: 30px;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s,visibility .3s;
        background-color: rgba(255, 255, 255, 0.95);
        color: initial;
    }

    &__submenu--inner {
        width: 100%;
        display: flex;
        gap: 20;
    }

    &__submenu--left {
        flex-basis: 520px;
        padding-right: 30px;
        border-right: 1px solid #333;
    }

    &__submenu--right {
        flex-basis: 350px;
        padding-left: 30px;
    }

    &__submenu--inner4{
        width: 100%;
        margin: 0px auto;
        display: flex;
        gap: 10;
    }

    // about / support

    &__submenu--inner2 {
        width: fit-content;
        margin: 20px auto;
        display: flex;
        justify-content: space-between;
        gap: 50px;
    }

    &__submenu--left2 {
        width: 275px;
        background-color: #444444;
    }

    &__submenu--right2 {
        width: 475px;
        display: flex;
        justify-content: space-between;

        ul {
            flex-basis: 50%;
            display: flex;
            flex-direction: column;
            justify-content: start;
        }

        ul > li {
            padding: 10px 0;
            font-size: 14px;
            font-weight: 700;
        }

    }

    &__submenu--inner3 {
        width:510px;
        display: flex;
        justify-content: space-between;
        gap: 0px;
    }
      
    &__submenu--left3 {
        width: 510px;
        padding-right: 0px;
    }
    &__submenu--right3 {
        flex-basis: 0px;
        padding-left: 0x;
    }

    &__submenu-p {
        font-size: 70%;
        font-weight: 500;
        margin-top: 0;
    }

    &__submenu-ttl1 {
        font-weight: 700;
        margin-bottom: 5px;
    }

    &__submenu-ttl2 {
        width: 100%;
        font-weight: 700;
        margin-top: 8px;
        margin-bottom: 10px;
        font-size: 80%;
    }

    // About Support

    &__submenu-ttl3,
    &__submenu-ttl4 {
        width: 275px;
        height: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: contain;
        color: #fff;
        font-weight: bold;
        text-align: center;
        line-height: 2;

        .catttl {
            font-weight: normal;
            font-size: 140%;
        }
    }

    // About
    &__submenu-ttl3 {
        background-image: url(/assets/img/common/menu_about.png);
    }

    // Support
    &__submenu-ttl4 {
        background-image: url(/assets/img/common/menu_support.png);
    }



    &__submenu-ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 20px;
        gap: 18px;

        li {
            display: block;
            width: 150px;
            &.list-1 {
                width: 100%;
            }
        }
    }

    &__submenu-ul2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 0px;
        gap: 18px;

        li {
            display: block;
            width: 150px;
            &.list-1 {
                width: 100%;
            }
        }
    }

    &__submenu-img {
        display: block;
        width: 150px;

        img {
            width: 150px;
        }
        
    }

    &__submenu-link {
        display: block;
    }

    &__submenu-linkBg {
        display: block;
        width: 150px;
        height: 110px;
        background-size: contain;
        background-repeat: no-repeat;

        // 家庭用製品画像 背景
        &--product_1_01 {
            background-image: url(/assets/img/common/menu_product_1_01.png);
        }
        &--product_1_02 {
            background-image: url(/assets/img/common/menu_product_1_02.png);
        }
        &--product_1_03 {
            background-image: url(/assets/img/common/menu_product_1_03.png);
        }
        &--product_1_04 {
            background-image: url(/assets/img/common/menu_product_1_04.png);
        }
        &--product_1_05 {
            background-image: url(/assets/img/common/menu_product_1_05.png);
        }
        &--product_1_06 {
            background-image: url(/assets/img/common/menu_product_1_06.png);
        }
        &--product_1_07 {
            background-image: url(/assets/img/common/menu_product_1_07.png);
        }

        // 業務用製品画像 背景
        &--product_2_01 {
            background-image: url(/assets/img/common/menu_product_2_01.png);
        }
        &--product_2_02 {
            background-image: url(/assets/img/common/menu_product_2_02.png);
        }
        &--product_2_03 {
            background-image: url(/assets/img/common/menu_product_2_03.png);
        }
        &--product_2_04 {
            background-image: url(/assets/img/common/menu_product_2_04.png);
        }
        &--product_2_05 {
            background-image: url(/assets/img/common/menu_product_2_05.png);
        }
        &--product_2_06 {
            background-image: url(/assets/img/common/menu_product_2_06.png);
        }
        &--product_2_07 {
            background-image: url(/assets/img/common/menu_product_2_07.png);
        }
        &--product_2_08 {
            background-image: url(/assets/img/common/menu_product_2_08.png);
        }
        &--product_2_09 {
            background-image: url(/assets/img/common/menu_product_2_09.png);
        }
        &--product_2_10 {
            background-image: url(/assets/img/common/menu_product_2_10.png);
        }
        &--product_2_11 {
            background-image: url(/assets/img/common/menu_product_2_11.png);
        }

        // オンラインショップ画像 背景
        &--shop_1_01 {
            background-image: url(/assets/img/common/menu_shop_1_01.png);
        }
        &--shop_1_02 {
            background-image: url(/assets/img/common/menu_shop_1_02.png);
        }
        &--shop_1_03 {
            background-image: url(/assets/img/common/menu_shop_1_03.png);
        }
    }

        &__submenu-linkBg2 {
            display: block;
            width: 300px;
            height: 110px;
            background-size: contain;
            background-repeat: no-repeat;    
        
        &--shop_2_01 {
            background-image: url(/assets/img/common/menu_shop_2_01.png);
        }
    }

    &__submenu-btn {
        display: block;
        width: 230px;
        margin: 30px auto 0;
        padding: 1.2em 0;
        text-align: center;
        border: 1px solid #000;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 100vh;
        cursor: pointer;
        color: #000;
        font-size: 80%;
        transition: all .3s;

        &:hover {
            color: #fff;
            background-color: rgba(0, 0, 0, 1);

            > .m-headerPC__submenu-btn-icon:after {
                background-image: url(/assets/img/common/menu_arw1_wh.svg);
            }
        }
    }

    &__submenu-btn-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__submenu-btn-icon:after {
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-top: 5px;
        margin-left: 10px;
        background-image: url(/assets/img/common/menu_arw1.svg);
        background-size: contain;
        background-repeat: no-repeat;
        vertical-align: middle;
        transition: all .3s;
    }

    &__fuki {
        // display: none;
        position: absolute;
        bottom: 0;
        left: calc(50% - 10px);
        transition: opacity .3s,visibility .3s;
        opacity: 0;
        visibility: hidden;
    }

    // メニュー詳細 表示
    &__link-item {
        display: block;
        margin-top: 30px;
        height: 50px;

        
        > span {
            display: inline-block;
            position: relative;
            padding: 0 20px;
            height: 100%;

            > a {
                color: #fff;
            }
        }

        .m-headerPC__submenu.isActive {
            opacity: 1;
            visibility: visible;
        }

        svg.m-headerPC__fuki.isActive {
            opacity: 1;
            visibility: visible;
        }
    }

    &__link-item2 {
        display: block;
        margin-top: 30px;
        height: 50px;

        
        > span {
            display: inline-block;
            position: relative;
            padding: 0 20px;
            height: 100%;

            > a {
                color: #fff;
            }
        }

        .m-headerPC__submenu.isActive {
            opacity: 1;
            visibility: visible;
        }

        svg.m-headerPC__fuki.isActive {
            opacity: 1;
            visibility: visible;
        }
    }


    &__onlineshop {
        display: block;
        display: inline-block;
        height: 27px;
        padding-left: 10px;
        margin-right: 30px;
        background-image: url("/assets/img/common/icon_cart.png");
        background-repeat: no-repeat;
        background-size: contain;    
    

        > span {
            padding-left: 20px;
        }

        .m-headerPC__submenu.isActive {
            opacity: 1;
            visibility: visible;
        }

        svg.m-headerPC__fuki.isActive {
            opacity: 1;
            visibility: visible;
        }
    }

    &__logo2 {

        padding-left: 40px;
        img {
            display: block;
            margin-left: auto;
            height: 60px;
        }
    }

}

.u-he92 {
    background-size: cover;
    background-position: center;
    height: 92px;
}
// m-headerPC END

// ===============================================
// m-headerSP
// ===============================================

.m-headerSP {
    z-index: 9998;
    display: none;
    @include sp{
        display: block;
        position: relative;
    }

    &__inner {
        width: 95%;
        height: 80px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo1 {
        img {
            height: 48px;
        }
    }

    &__wrpnav {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__onlineshop {
        margin-right: 30px;
    }

    &__burger2 {
        margin-right: 30px;
        margin-bottom: 12px;
        position: relative;
        width: 28px;
        height: 15px;

        span {
            position: absolute;
            display: block;
            width: 28px;
            height: 1px;
            background-color: #fff;
            transition: all .5s;
        }
    }

    &__burger2.open {

        span {
            opacity: 0.5;
        }
    }

    &__burger3 {
        width: 300px;
        margin: 0 auto;
        position: relative;
        background-color: #000;
        border-radius: 100vh;
    
        a {
            display: block;
            
        }

        span {
            display: block;
            margin: 0 auto;
            padding: 10px 0;
            text-align: center;
            color: #fff;
            font-size: 13px;

            &:before {
                content: "";
                display: inline-block;
                width: 40px;
                height: 22px;
                position: absolute;
                left: 40px;
                top: calc(50% - 15px);
                background-image: url(/assets/img/common/icon_cart.svg);
                background-repeat: no-repeat;
                background-size: contain;
            }

            &:after {
                content: "";
                display: inline-block;
                width: 30px;
                height: 22px;
                position: absolute;
                right: 25px;
                top: calc(50% - 10px);
                background-image: url(/assets/img/common/menu_arw1_wh.svg);
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }

    &__burger {
        position: relative;
        width: 30px;
        height: 16px;

        span {
            position: absolute;
            display: block;
            width: 30px;
            height: 1px;
            background-color: #fff;
            transition: all .5s;

            &:nth-child(1) {
                top: 0px;
            }
    
            &:nth-child(2) {
                top: 8px;
            }
    
            &:nth-child(3) {
                top: 16px;
            }
        }
    }

    &__burger.open {
        span {
    
            &:nth-child(1) {
                transform: translateY(8px) rotate(-35deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                transform: translateY(-8px) rotate(35deg);
            }
        }
    }

    &__navwrapper {
        display: block;
        position: absolute;
        top: 80px;
        width: 100%;
        left: 0;
        height: calc(100vh - 80px);
        background-color: rgba(255, 255, 255, 0.95);
        background-color: #fff;
        padding: 0 10px 20vh;
        overflow-y: scroll;
        overscroll-behavior: none;
        transition: all .5s;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s,visibility .3s;
    }

    &__navwrapper.open {
        opacity: 1;
        visibility: visible;
    }
    &__navwrapper2 {
        display: block;
        position: absolute;
        top: 80px;
        width: 100%;
        left: 0;
        height: calc(100vh - 80px);
        background-color: rgba(255, 255, 255, 0.95);
        background-color: #fff;
        padding: 0 10px 20vh;
        overflow-y: scroll;
        overscroll-behavior: none;
        transition: all .5s;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s,visibility .3s;

        span {
            font-size: 130%;
            font-weight: 700;
        }
    }

    &__navwrapper2.open {
        opacity: 1;
        visibility: visible;
    }

    &__shop {
        align-items: center;

        span {
            font-size: 130%;
            font-weight: 700;
        }
    }
    &__shopttl{
            font-size: 130%;
            font-weight: 700;
            margin-bottom: 30px;
        }

    &__shop2 {
        width: 100%;
        margin-top: 40px;
        align-items: left;
    }

    &__shopsubinner {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            
            li {
                flex-basis: 50%;
                
    
                a {
                    display: block;
                }
    
                .linkttl {
                    display: block;
                    // margin: 5px 0 10px;
                    margin: 5px 0 10px;
                    font-size: 12px;
                }
            }
        }

    &__topLogo {
        width: 80px;
        margin: 0 0 20px auto;
    }

    &__mainnav{
        padding: 15px 0;
    }

    &__catttl {
        font-size: 17px;
        font-weight: 700;
    }

    &__brandwrp {
        padding-top: 10px;
    }

    &__brandlogo {
        width: 80px;
        height: 80px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #F4F3F4;

        img {
            width: 80%;
        }
    }

    &__brandlogoInner {
        width: 80%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;

        &--melitta {
            background-image: url(/assets/img/common/drop_logo_melitta_h.svg)
        }

        &--zh {
            background-image: url(/assets/img/common/zh_menu_icon.svg)
        }

        &--dallacorte {
            background-image: url(/assets/img/common/dallacorte-logo.png)
        }

        &--poursteady {
            background-image: url(/assets/img/common/poursteady-logo.png)
        }

        &--mahlkonig {
            background-image: url(/assets/img/common/Mahlkonig-logo.png)
        }

        &--puqpress {
            background-image: url(/assets/img/common/PUQpress-logo2.png)
        }

        &--latteartfactory {
            background-image: url(/assets/img/common/latteartfactory-logo.png)
        }
    }

    &__abouticon {
        width: 100px;
        height: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid #F4F3F4;

        

        &--about {
            background-image: url(/assets/img/common/menu_about_sp.png);
        }

        &--support {
            background-image: url(/assets/img/common/menu_support_sp.png);
        }
    }

    &__brandbtn {
        display: flex;
        position: relative;
        align-items: center;
        width: 100%;
        background-color: #F4F3F4;
    }

    &__brandname {
        padding-left: 20px;
    }

    &__brandown {
        position: absolute;
        top: calc(50% - 8px);
        right: 20px;
        display: inline-block;
        width: 10px;
        height: 10px;
        border-right: 2px solid #000;
        border-bottom: 2px solid #000;
        transform: rotate(45deg);
        overflow: visible;
    }

    &__brandbtn.isActive &__brandown {
        transform: rotate(-135deg);
        top: calc(50% - 5px);
    }

    &__submenu {
        display: none;
        margin-top: 5px;
    }

    &__submenu.open {
        display: block;
    }

    &__submenuinner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        
        li {
            flex-basis: 50%;
            

            a {
                display: block;
            }

            .linkttl {
                display: block;
                // margin: 5px 0 10px;
                margin: 5px 0 10px;
                font-size: 12px;
            }
        }
    }


    &__submenu-linkBg {
        display: block;
        width: 100%;
        height: 0;
        padding-top: calc( 456 / 624 * 100%);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        
        // 家庭用製品
        &--product_1_01 {
            background-image: url(/assets/img/common/menu_product_1_01.png);
        }
        &--product_1_02 {
            background-image: url(/assets/img/common/menu_product_1_02.png);
        }
        &--product_1_03 {
            background-image: url(/assets/img/common/menu_product_1_03.png);
        }
        &--product_1_04 {
            background-image: url(/assets/img/common/menu_product_1_04.png);
        }
        &--product_1_05 {
            background-image: url(/assets/img/common/menu_product_1_05.png);
        }
        &--product_1_06 {
            background-image: url(/assets/img/common/menu_product_1_06.png);
        }

    }

    &__submenuinner_shop {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        
        li {
            flex-basis: 40%;
            margin: 5px;
            

            a {
                display: block;
            }

            .linkttl {
                display: block;
                // margin: 5px 0 10px;
                margin: 5px 0 10px;
                font-size: 12px;
            }
                .ttl {
                    font-weight: 600;
                    font-size: 100%;   
            }

            }
        }

    &__submenuinner_shop2 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        
        li {
            width: 40%;
            margin: 5px;
            

            a {
                display: block;
            }

            .linkttl {
                display: block;
                // margin: 5px 0 10px;
                margin: 5px 0 10px;
                font-size: 12px;
            }
        }
    }


    &__submenu-linkBg2 {
        display: block;
        width: 100%;
        height: 0;
        padding-top: calc( 456 / 624 * 100%);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        // オンラインショップ
        &--shop_1_01 {
            background-image: url(/assets/img/common/menu_shop_1_01.png);
        }
        &--shop_1_02 {
            background-image: url(/assets/img/common/menu_shop_1_02.png);
        }
        &--shop_1_03 {
            background-image: url(/assets/img/common/menu_shop_1_03.png);
        }
    }

    &__submenu-linkBg3 {
        display: block;
        width: 300px;
        height: 120px;
        background-size: contain;
        background-repeat: no-repeat;   

        &--shop_2_01 {
            background-image: url(/assets/img/common/menu_shop_2_01.png);
        }
    }
        
    &__submenuinner2 {
        
        li {
            position: relative;
            
            a {
                display: inline-block;
                width: 100%;
                padding: 10px 0;
                font-size: 15px;
                border-bottom: 1px solid #EAEAEA;
                

                &:after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    top: calc(50% - 5px);
                    right: 0px;
                    width: 20px;
                    height: 5px;
                    background-image: url(/assets/img/common/menu_arw1.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                }

            }

            &:last-child {
                margin-bottom: 20px;
            }

        }
    }

    // 商品画像枠線有
    &__submenuinner3 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        
        li {
            flex-basis: 50%;
            

            &:nth-child(even) {
                margin-left: -1px;
            }
            

            a {
                display: block;
            }

            img {
                border: 1px solid #f3f3f3;
            }

            .linkttl {
                display: block;
                margin: 5px 0 10px;
                font-size: 12px;
            }
        }
    }

    &__submenu-linkBgBorder {
        display: block;
        width: 100%;
        height: 0;
        padding-top: calc( 456 / 624 * 100%);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid #f3f3f3;

        // 業務用製品
        &--product_2_01 {
            background-image: url(/assets/img/common/menu_product_2_01.png);
        }
        &--product_2_02 {
            background-image: url(/assets/img/common/menu_product_2_02.png);
        }
        &--product_2_03 {
            background-image: url(/assets/img/common/menu_product_2_03.png);
        }
        &--product_2_04 {
            background-image: url(/assets/img/common/menu_product_2_04.png);
        }
        &--product_2_05 {
            background-image: url(/assets/img/common/menu_product_2_05.png);
        }
        &--product_2_06 {
            background-image: url(/assets/img/common/menu_product_2_06.png);
        }
        
    }


    &__submenuinnerbtn {
        display: block;
        width: 80%;
        margin: 10px auto;
        padding: 10px 0;
        text-align: center;
        border: 1px solid #000;
        border-radius: 100vh;
        font-size: 12px;

        span:after {
            content: "";
            display: inline-block;
            width: 20px;
            height: 5px;
            margin-left: 10px;
            margin-bottom: 2px;
            background-image: url("/assets/img/common/menu_arw1.svg");
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &__aboutwrp {
        margin-top: 10px;
        padding: 20px 0;
        border-top: 1px solid #707070;
    }

    &__social {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        

        &--icon {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: 20px;

            }
        }
    }  
}

// TOP以外のヘッダーメニュー

.l-header{
    position: absolute;
    background-color: $color_04;
    width: 100%;
    top: 0;
    z-index: 999;
    transition: all .6s ease;
    // border-bottom: 1px solid transparent;
    position: fixed;
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.11);

    &::after{
        transition:opacity 1s;
        opacity: 0;
    }

    &__cont{
        position: relative;
    }

    &__inner{
        display: flex;
        margin: 0 65px 0 85px;
        justify-content: space-between;
    }

    &__logo{
        display: inline-block;
        width: 138px;
        margin: 8px 16px 8px 0;

        @include sp{
            width: 153/750*100vw;
        }
        
        img{
            width: 100%;
        }
    }
}

.l-headerPc{
    display: block;
    @include sp{
        display: none;
    }
}

.l-headerUpper{
    background-color: $color_05;
    padding-right: 85px;

    &__list{
        display: flex;
        justify-content: space-between;
        width: 710px;
        margin-left: auto;
        position: relative;
        z-index: 1;
    }

    &__logo{
        display: inline-block;
        margin-top:0px;
        margin-right:-20px;
        margin-bottom:-35px;
    }

    &__link{
        color: $color_04;
        display: block;
        height: 100%;
        padding: 7px 0 6px;
        border-bottom: 1px solid transparent;
        line-height: 1.53;
        font-size: 15px;

        &--line{
            &:hover{
                opacity: 1;
                text-decoration: underline;
                text-underline-offset: 3px;
            }
        }

        &--red{
            background-color: #CF0A2C;
            padding: 7px 20px 6px;
        }
    }
}

.l-headerMenu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 883px;

    &__item{
        a{
            color: $color_05;
            display: inline-block;
            position: relative;
            text-align: center;
            transition: .3s;
            font-size: 15px;
            letter-spacing: 0.08em;
            text-decoration: none;
            padding: 36px 0 38px;
            line-height: 1.53;

            &::after {
                position: absolute;
                bottom: -1px;
                left: 0;
                content: '';
                width: 100%;
                height: 2px;
                background: rgb(207,10,44);
                transform: scale(0, 1);
                transform-origin: center top;
                transition: transform .5s;
                z-index: 1;
            }

            &:hover{
                &:after {
                    transform-origin: center top;
                    transform: scale(1, 1);
                    height: 2px;
                }
                
            }
        }
    }
}

.l-headerDropCont{
    position: relative;
}

.l-headerDrop{
    background-color: $color_06;
    position: absolute;
    width: 100%;
    opacity: 1;
    z-index:1;
    left: 0;
    top: 1px;
    display: none;

    &.is-active{
        top: 0;
        opacity: 1;
        transition: all .6s ease;
        visibility: visible;
        z-index: 999;
    }

    &__inner{
    }

    &__flex{
        display: flex;
        max-width: 884px;
        margin: 0 auto;
        padding: 40px 0;
        width: 100%;
        justify-content: space-between;
    }

    &__list{
        transition: all .3s ease;
    }

    &__item{
        font-size: 13px;
        margin-bottom: 20px;

        &--logo{
            height: 37px;
            margin-bottom: 16px;
    
            img{
                vertical-align: middle;
            }
        }

        a{
            white-space: nowrap;
        }
    }
}

.l-headerInfo{
    max-width: 925px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__inner{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
    }

    &__txt{
        font-size: 14px;
        line-height: 1.5;
    }

    &__list{
        display: flex;
    }

    &__item{
        font-size: 14px;
        line-height: 1.5;

        .type{
            position: relative;
            padding-right: 28px;

            &::after{
                position: absolute;
                content: '';
                background: url(/assets/img/common/header_dial_icon.svg);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                width: 19px;
                height: 12px;
                top: 50%;
                right: -6px;
                transform: translate(-50%,-50%);
            }
        }
        .tel{
            font-weight: bold;
        }
    }

    &__link{
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 14px;
        position: relative;
        padding-right: 64px;
        padding-left: 17px;
        white-space: nowrap;
        cursor: pointer;
        transition: all .2s;
        
        &:nth-child(3) {
            cursor: default;
        }

        &::after{
            content: '';
            height: 1px;
            width: 34px;
            background-color: $color_05;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
        }

        &:hover {
            background-color: #444;
            opacity: 1;
            color: #fff;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(4) {
                text-decoration: underline;
                text-underline-offset: 3px;
            }
            &::after{
                background-color: #fff;
            }

            .l-headerInfo__subMenu {
                display: block;
            }

            .l-headerInfo__items {
                color:#444444;
            }

        }

        &:nth-child(3),
        &:nth-child(4) {
            padding-right: 56px;
        }
    }
    
    &__alink{
        color:inherit;
        &:hover {
            color:#fff;
            background-color: #444444;
            opacity: 1;
            text-decoration: underline;
            text-underline-offset: 3px;
        }
    }

    &__subMenu {
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;

        display: none;

        li {
            background-color: #E3E3E3;
            border-top: 1px solid #C7C7C7;

            &:first-child {
                border-top: none;
            }
        }

        a {
            display: block;
            padding: 14px 17px;

            font-size: 14px;
            font-weight: normal;
            line-height: 24px;
            color: #444444;

            &:hover {
                background-color: #C7C7C7;
                opacity: 1;
                text-decoration: underline;
                text-underline-offset: 3px;
            }
        }
    }
}
