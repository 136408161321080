@charset "utf-8";

.ham a:visited {
	color: inherit;
}

@include sp{
    .ham{
        position: relative;
    }

    .ham__bg {
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: 999;
		background-color: rgba(0, 0, 0, 0.7);
		display: none;
		top: 0;
		left: 0;
	}

	.ham__button {
        display: block;
        background-color: transparent;
        color: $color_04;
        border: none;
        letter-spacing: 0.1em;
        cursor: pointer;
        position: fixed;
        top: 10px;
        right: 10px;
        z-index: 1002;
        outline: none;
        -webkit-tap-highlight-color:rgba(0,0,0,0);
	}

    .ham__bar1 {
        &.white{
            background-color: $color_04;
        }
	}

    .ham__bar2 {
        &.white{
            background-color: $color_04;
        }
	}

	.ham__bar {
		display: block;
		height: 2px;
		margin: 25/750*100vw 0;
		transition: all 0.5s;
	}

    .ham__bar1{
        width: 80/750*100vw;
        margin-left: auto;
        background-color: $color_05;

    }

    .ham__bar2{
        width: 50/750*100vw;
        margin-left: auto;
        background-color: $color_05;
    }

    .ham__button.btn-active .ham__bar {
		width: 40px;
	}

	.ham__button.btn-active .ham__bar1 {
        transform: rotate(45deg) translateX(21/750*100vw);
        background-color: $color_05;
        width: 80/750*100vw;
	}

	.ham__button.btn-active .ham__bar2 {
        transform: rotate(-45deg) translateX(21/750*100vw);
        background-color: $color_05;
        width: 80/750*100vw;
	}

    .ham__header{
        width: 100vw;
        height: 140/750*100vw;
        background: $color_04;
        padding: 17/750*100vw 0 0 30/750*100vw;
        z-index: 1001;
        border-bottom: 2px solid $color_06;
        position: fixed;
    }

    .ham__headerLogo{
        width: 176/750*100vw;
    }

	.ham__navWrapper {
		width: 618/750*100vw;
        height: 100vh;
		transition: all 0.7s;
		transform: translate(618/750*100vw);
		position: fixed;
		top: 0;
		right: 0;
		z-index: 1000;
		background-color: $color_04;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	.ham__nav {
		display: block;
		position: relative;
		margin-top: 140/750*100vw;
        min-height: 120vh;
        padding-bottom: 100/750*100vw;
        overflow-y: scroll;
	}

	.ham__navItem {
        background-color: $color_04;
		height: auto;
		line-height: 50px;
		display: block;
		text-align: left;
        position: relative;
	}

    .ham__navLink{
        padding-left: 47/750*100vw;
        line-height: 140/750*100vw;
        display: block;
        border-bottom: 1px solid $color_06;

        @include sp{
            @include vw(32);
        }

        &--bold{
            border-bottom: 2px solid $color_06;
        }

        .hamburger2__navWrapper2 {
            width: 618/750*100vw;
            height: 100vh;
            transition: all 0.7s;
            transform: translate(618/750*100vw);
            position: fixed;
            top: 0;
            right: 0;
            z-index: 1000;
            background-color: $color_04;
            overflow-x: hidden;
            overflow-y: scroll;
        }        
    }

	// .ham__secondList {
	// 	display: none;
	// }

    .ham__secondLink{
        background-color: $color_06;
        border-bottom: 1px solid $color_04;
        display: block;
        padding-left: 180/750*100vw;

        &--ml{
            &:before{
                content: '';
                background: url(/assets/img/common/ml_menu_icon.svg);
                background-repeat: no-repeat;
                background-size: contain;
                width: 105/750*100vw;
                height: 64/750*100vw;
                position: absolute;
                left: 46/750*100vw;
                top: 40/750*100vw;
            }
        }

        &--dc{
            &:before{
                content: '';
                background: url(/assets/img/common/dc_menu_icon.svg);
                background-repeat: no-repeat;
                background-size: contain;
                width: 106/750*100vw;
                height: 62/750*100vw;
                position: absolute;
                left: 46/750*100vw;
                top: 40/750*100vw;
            }
        }

        &--mk{
            &:before{
                content: '';
                background: url(/assets/img/common/mk_menu_icon.svg);
                background-repeat: no-repeat;
                background-size: contain;
                width: 111/750*100vw;
                height: 63/750*100vw;
                position: absolute;
                left: 46/750*100vw;
                top: 40/750*100vw;
            }
        }

        &--ps{

            &:before{
                content: '';
                background: url(/assets/img/common/ps_menu_icon.svg);
                background-repeat: no-repeat;
                background-size: contain;
                width: 125/750*100vw;
                height: 12/750*100vw;
                position: absolute;
                left: 33/750*100vw;
                top: 67/750*100vw;
            }
        }

        &--zh{
            border-bottom: none;

            &:before{
                content: '';
                background: url(/assets/img/common/zh_menu_icon.svg);
                background-repeat: no-repeat;
                background-size: contain;
                width: 126/750*100vw;
                height: 50/750*100vw;
                position: absolute;
                left: 32/750*100vw;
                top: 41/750*100vw;
            }
        }

				&--pp{
            &:before{
                content: '';
                background: url(/assets/img/common/pp_menu_icon.svg);
                background-repeat: no-repeat;
                background-size: contain;
                width: 112/750*100vw;
                height: 72/750*100vw;
                position: absolute;
                left: 46/750*100vw;
                top: 40/750*100vw;
            }
        }
    }

    .ham__secondItem{
        position: relative;

        @include sp{
            @include vw(32);
        }
    }

    .ham__secondLink{
        line-height: 140/750*100vw;
    }

    .ham__thirdList {
		display: none;
	}

    .ham__thirdItem{
        color: $color_04;

        &--ml{
            position: relative;
            background-color: #444444;
            padding-left: 47/750*100vw;
            line-height: 140/750*100vw;
            border-bottom: 1px solid $color_06;
            display: block;

            a{
                color: $color_04;
            }
        }
    }

    .ham__thirdItem a{
        color: $color_04;
        line-height: 140/750*100vw;
        border-bottom: 1px solid #C7C7C7;
        width: 100%;
        display: block;
        background: $color_05;
        padding-left: 47/750*100vw;
        white-space: nowrap;

        @include sp{
            @include vw(28);
        }
    }

    .ham__fourthList {
		display: none;
	}

    .ham__fourthItem{

    }

    .ham__fourthItem a{
        color: $color_04 !important;
        line-height: 140/750*100vw;
        border-bottom: 1px solid #C7C7C7;
        width: 100%;
        display: block;
        background: $color_05;
        padding-left: 47/750*100vw;
        white-space: nowrap;

        @include sp{
            @include vw(28);
        }
    }

	.ham__navWrapper.is-open {
		transform: translate(0);
	}

    .hamburger2__navWrapper2.is-open {
		transform: translate(0);
	}

	.touch-btn{
        position: absolute;
        right: 40/750*100vw;
        top: 50/750*100vw;
        width: 40/750*100vw;
        height: 40/750*100vw;
        -webkit-tap-highlight-color:rgba(0,0,0,0);

        &:before{
            display: block;
            content: "";
            position: absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            margin:auto;
            width: 100%;
            height: 1px;
            transition: .2s;
            transform: rotate(90deg);
        }

        &:after{
            display: block;
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 100%;
            height: 1px;
            transition: .3s;
        }

        &.is-active{
            &:before{
                transform: rotate(0deg);
            }
            &:after{
                background-color: transparent;
            }
        }

        &--red{
            &:before{
                background-color: #CF0A2C;
            }
            &:after{
                background-color: #CF0A2C;
            }
        }

        &--black{
            &:before{
                background-color: $color_05;
            }
            &:after{
                background-color: $color_05;
            }
        }

        &--white{
            &:before{
                background-color: $color_04;
            }
            &:after{
                background-color: $color_04;
            }
        }
	}

    .ham__info{
        position: absolute;
        background-color: $color_04;
        top: 140/750*100vw;
        left: 0;
        width: 100%;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    }

    .ham__infoInner{
        width: 670/750*100vw;
        padding: 24/750*100vw 0;
        margin: 0 auto;
    }

    .ham__infoTxtCont{

    }

    .ham__infoTxt{
        @include sp{
            @include vw(24);
            line-height: 1.5;
            white-space: nowrap;

            span{
                position: relative;
                padding-right: 40/750*100vw;

                &::after{
                    position: absolute;
                    content: '';
                    background: url(/assets/img/common/header_dial_icon.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    width: 25/750*100vw;
                    height: 22/750*100vw;
                    top: 50%;
                    right: -7/750*100vw;
                    transform: translate(-50%,-50%);
                }
            }

            a{
                font-weight: bold;
            }
        }
    }

    .ham__infoLinkCont{
        text-align: right;
        padding-right: 60/750*100vw;
        margin-top:5px;
        margin-bottom:15px;
    }

    .ham__infoLink{
        @include sp{
            @include vw(24);
            position: relative;

            &::after{
                content: '';
                height: 1px;
                width: 55/750*100vw;
                background-color: $color_05;
                position: absolute;
                top: 50%;
                right: -65/750*100vw;
                transform: translateY(-50%);
            }
        }
    }
}
